import right_login from '../../asset/img/right_login.png'
import email from '../../asset/img/email.svg'
import password from '../../asset/img/password.svg'
import show from '../../asset/img/show.svg'
import './style.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../component/Input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = ({ }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            navigate('/user');
        }
    }, [navigate]);

    const showNotification = (text) => {
        toast.error(text, {
            position: "bottom-right",
            closeOnClick: false,
            autoClose: 3000
        });
    };


    const login = async () => {
        const response = await fetch("https://prodapi.viiapp.sofenty.com/admin/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        });

        // Parse JSON response
        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('access_token', data.access_token);
            navigate('/user');
        } else {
            showNotification('Логін або пароль введені неправильно.')
        }
    }

    return (
        <div className='login'>
            <div className='left'>
                <div className='title'>Увійти</div>
                <Input name={'Електронна пошта'} icon={email} showPassword={true} onChange={e => setUser({ ...user, email: e.target.value })} value={user.email} placeholder={'Введіть вашу електронну адресу'} />
                <Input name={'Пароль'} show={show} setShowPassword={setShowPassword} icon={password} type={true} onChange={e => setUser({ ...user, password: e.target.value })} value={user.password} placeholder={'Введіть ваш пароль'} showPassword={showPassword} />
                <button onClick={login}>Вхід</button>
            </div>
            <div>
                <img className='rightImage' src={right_login} />
            </div>
            <ToastContainer closeButton={false} />
        </div>
    )
}